import axios from 'axios';
import { serverUrl } from '../../config';
import { SET_BUSINESS, SET_AREAS } from './types';

export const fetchAreas = (data) => async (dispatch) => {


  return await axios({
    url: "api/user/menu/areas?restaurant=" + data,
    method: "GET",
    baseURL: serverUrl,
  }).then(res => {


    dispatch({
      type: SET_AREAS,
      payload: res.data.payload,

    });
    return res
  }).catch(err => {
    console.log(err);
  })



};
