import axios from 'axios';
import { serverUrl } from '../../config';
import {
  SET_BUSINESS,
  SET_MENU,
  SET_ORDER_DETAILS,
  SET_BUSINESS_CONFIG,
} from './types';

export const fetchMenu = (data) => async (dispatch) => {
  const apiCall = await axios({
    url: 'api/user/menu?restaurant=' + data,
    method: 'GET',
    baseURL: serverUrl,
    // headers:{"x-auth-token":token},
    // data,
  });
  dispatch({
    type: SET_MENU,
    data: {
      menu: apiCall.data.payload,
    },
  });

  return apiCall;
};

export const fetchBusinessConfig = (data) => async (dispatch) => {
  return await axios({
    url: 'api/user/business?id=' + data,
    method: 'GET',
    baseURL: serverUrl,
  })
    .then((res) => {
      let data = {
        ...res.data.payload,
        images: JSON.parse(res?.data?.payload?.images),
      };
      dispatch({
        type: SET_BUSINESS_CONFIG,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchOrderDetails = (id) => async (dispatch) => {
  const apiCall = await axios({
    url: 'api/user/menu/order?id=' + id,
    method: 'GET',
    baseURL: serverUrl,
    // headers:{"x-auth-token":token},
    // data,
  });
  console.warn('fetchOrderDetails',apiCall.data.payload);
  dispatch({
    type: SET_ORDER_DETAILS,
    data: {
      orderDetails: apiCall.data.payload,
    },
  });

  return apiCall;
};
