import React from "react";
import AddIcon from "@material-ui/icons/Add";
import noImage from "./../assets/no-image.png";
import { Button, IconButton, TextField } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import custom from "./Component.module.css";
import { showPrice } from "../resources/currency";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

// const buttonStyle = {};

function CartItem(props) {
  let item_price = props?.details?.item_price;
  let actual_Price = item_price;
  let discounted_price = props?.details?.discounted_price;
  props?.details?.options?.forEach((data) => {
    item_price += data.option_price;
  });
  let discount = props?.details?.item_price - props?.details?.discounted_price;

  const excerpt = (str) => {
    let stringLength = str.length;
    if (stringLength > 50) {
      str = str.substring(0, 50) + " ... ";
    }
    return str;
  };

  return (
    <div
      style={{ width: "100%" }}
      // className={custom.cartItems}
    >
      <div className={custom.cartItem}>
        <div className={custom.cartItemRow}>
          {/* <div className={custom.cartItemBox}>
            <img
              alt={props.details.item_title}
              src={
                props.details.image_link ? props.details.image_link : noImage
              }
              style={{
                width: props.details.image_link ? '100%' : '80%',
                height: props.details.image_link ? '100%' : '80%',
                borderRadius: 5,
                objectFit: 'cover',
              }}
            />
          </div> */}
          {/* {console.log("props details in item cart: 54 ---", props.details)} */}

          {props.hideDelete ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              <span
                className={custom.cartItemButtonDrawer}
                onClick={() => props.AddQuantity()}
              >
                +
              </span>
              <input
                type="number"
                defaultValue={props.item.quantity}
                value={props.item.item_quantity}
                disabled={true}
                className={custom.cartItemInputNumberDrawer}
                style={{ flex: 1 }}
              />
              {/* <div className={custom.cartItemInputButtons}> */}

              <span
                onClick={() =>
                  props.item.item_quantity === 1
                    ? props.onDelete()
                    : props.MinusQuantity()
                }
                className={custom.cartItemButtonDrawer}
              >
                -
              </span>
              {/* </div> */}
            </div>
          ) : null}
          <div className={custom.titleDiv}>
            <p
              style={
                props.hideDelete
                  ? { width: "100%" }
                  : { marginBottom: 4, width: "100%" }
              }
            >
              <b className={custom.itemTitle} style={{ textAlign: "center" }}>
                {excerpt(props.details.item_title)}
              </b>
              {/* {console.log("-----------", props.details)} */}
            </p>
            {/* {props.details?.options?.map((value, index) => {
              return (
                <span className={custom.cartItemOptions} key={value?.option}>
                  {`${value?.option} ${showPrice(value?.option_price)}`}
                  {props.details.options.length - 1 === index ? ". " : ", "}
                </span>
              );
            })} */}
            <div>
              {props.details?.instruction && (
                <span className={custom.cartItemOptions}>
                  {props.details?.instruction}
                </span>
              )}
            </div>
          </div>
          {!props.hideDelete ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              <span
                onClick={() =>
                  props.item.item_quantity === 1
                    ? props.onDelete()
                    : props.MinusQuantity()
                }
                className={custom.cartItemButton}
              >
                {/* { props.item.item_quantity === 1 ? <DeleteOutline style={{fontSize: 20}}/> : "-" }  */}
                -
              </span>
              <input
                type="number"
                defaultValue={props.item.quantity}
                value={props.item.item_quantity}
                disabled={true}
                className={custom.cartItemInputNumber}
                style={{ flex: 1 }}
              />
              {/* <div className={custom.cartItemInputButtons}> */}

              <span
                className={custom.cartItemButton}
                onClick={() => props.AddQuantity()}
              >
                +
              </span>
              {/* </div> */}
            </div>
          ) : null}
          <div className={custom.cartItemPrice}>
            {discount !== 0 && (
              <strike style={{ margin: 0 }}>
                {showPrice(actual_Price * props.item.item_quantity)}
              </strike>
            )}
            <p
              style={
                props.hideDelete ? { margin: 0 } : { margin: "5px 0px 0px 0px" }
              }
            >
              {showPrice(discounted_price * props.item.item_quantity)}
            </p>
          </div>
        </div>
      </div>
      {props.details?.options?.map((value, index) => (
        <span
          key={value?.option}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "15px",
            // width: "95%",
            opacity: "0.5 !important",
            fontSize: "14px",
          }}
        >
          <span>
            {value?.option}{" "}
            {props.details.options.length - 1 === index ? ". " : ", "}
          </span>
          <span />
          <span style={{ marginRight: "10px" }}>
            {showPrice(value?.option_price)}
          </span>
        </span>
      ))}
      <div style={{ display: "flex", marginRight: 10 }}>
        {!props.hideDelete && (
          // <Button
          //   variant="outlined"
          //   className={custom.cartItemDelete}
          //   size="small"
          //   disableElevation
          //   onClick={() => {
          //     props.onDelete();
          //   }}
          // >
          //   {/* <DeleteOutline /> */}
          //   Remove
          // </Button>
          <div>
            {/* <span className={custom.removeButton}>
                  Update
            </span> */}
            <span
              className={custom.removeButton}
              onClick={() => {
                props.onDelete();
              }}
            >
              Remove
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default CartItem;
