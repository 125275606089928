/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import HeaderCarousel from "../components/HeaderCarousel";
import NavBar, { ElementsWrapper } from "react-scrolling-nav";
import {
  AppBar,
  Tab,
  Tabs,
  Grid,
  Button,
  TextField,
  SwipeableDrawer,
} from "@material-ui/core";
import Select from "@mui/material/Select";
import MUIMenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "../components/MenuItem";
import { Element, scroller } from "react-scroll";
import emptyCart from "./../assets/empty-cart.png";
import { makeStyles } from "@material-ui/core/styles";
import CartItem from "../components/CartItem";
import useWindowSize from "../helperFunctions/useWindowSize";
import { connect, useDispatch, useSelector } from "react-redux";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import { ShoppingBasketOutlined } from "@material-ui/icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import TrackVisibility from "react-on-screen";
import { fetchMenu, fetchBusinessConfig } from "../redux/actions/business";
import { useTranslation } from "react-i18next";
import { fetchAreas } from "../redux/actions/addressInfo";
import { createOrder } from "../redux/actions/order";
import TimingModal from "../components/TimingModal";
import LocationModal from "../components/LocationModal";
import custom from "./MenuPage.module.css";
import ItemModal from "../components/ItemModal";
import { identifier } from "../config";
import Navbar from "../components/Navbar";
import TimingTable from "../components/TimingTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Scrollspy from "react-scrollspy";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { showPrice } from "../resources/currency";
import CustomSearch from "../components/CutomSearch/CustomSearch";

const useStyles = makeStyles((theme) => ({
  cartContainer: {
    position: "sticky",
    top: "1rem",
    minWidth: "275",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    borderLeft: "solid rgba(0,0,0,0.05) 1px",
    padding: 10,
  },
  cartContainerSidebar: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    // border: '2px solid var(--primary-color)',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    width: "40%",
    maxWidth: "40%",
    borderRadius: 10,
    border: 0,
    appearance: "none",
    display: "flex",
  },
  dialogTitle: {
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  },
  viewCartMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
}));

function MenuPage(props) {
  const { t } = useTranslation();
  const { menu, business } = props;
  let businessIdentifier = identifier;
  const classes = useStyles();
  const size = useWindowSize();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [orderTotal_tax, setOrderTotal_tax] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [divIdArray, setDivIdArray] = useState([]);
  const [minimumOrderAlert, setMinimumOrderAlert] = useState(false);
  const [totalTax, setTotalTax] = useState(0);
  const [taxDetail, setTaxDetail] = useState([]);

  const [formValues, setFormValues] = useState({
    selectedCity: {
      value: "",
      error: false,
      errorMessage: "You select a city",
    },
    selectedArea: {
      value: "",
      error: false,
      errorMessage: "You select a area",
    },
    firstName: {
      value: "",
      error: false,
      errorMessage: "You must enter your first name",
    },
    lastName: {
      value: "",
      error: false,
      errorMessage: "You must enter your last name",
    },
    address: {
      value: "",
      error: false,
      errorMessage: "You must enter your address",
    },
    contact: {
      value: "+92",
      error: false,
      errorMessage: "You must enter your contact",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "You must enter your email",
    },
  });
  const [taxes, setTaxes] = useState([]);
  const [checkBox, setCheckbox] = useState([]);
  const [radio, setRadio] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [itemQuantity, setQuantity] = useState(1);
  const [isResturantClosed, setIsResturantClosed] = useState(false);
  const [showCartMobile, setShowCartMobile] = useState(false);
  const [orderType, setOrderType] = useState(1); // 1=delivery //2=take away //3=dine in
  const [showTimingModal, setShowTimingModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [placeOrderModalOpen, setPlaceOrderModalOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(query);
  const [data, setData] = useState([]);
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => setQuery(debouncedTerm), 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  const [fontSize, setFontSize] = useState(getResponsiveFontSize());

  useEffect(() => {
    const handleResize = () => {
      setFontSize(getResponsiveFontSize());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getResponsiveFontSize() {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1200) return "150%"; // Large screens
    if (windowWidth >= 768) return "150%"; // Tablets
    return "140%"; // Mobile screens
  }

  useEffect(() => {
    if (query !== "") {
      onSearchSubmit(query);
    } else {
      clearResults();
    }
  }, [query]);

  useEffect(() => {
    if (menu.length > 0 && menuData.length === 0) {
      setData(menu.slice());
      setMenuData(menu.slice());
    }
  }, [menu]);
  const onSearchSubmit = (value) => {
    let tempData = menuData.slice();
    let finalData = [];
    for (let i = 0; i < tempData.length; i++) {
      let filteredData = tempData[i].menu_items.filter((item) =>
        item.item_title.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length > 0) {
        const temp = JSON.parse(JSON.stringify(tempData[i]));
        temp.menu_items = [...filteredData];
        finalData.push(temp);
      }
    }
    setData(finalData);
  };
  const clearResults = () => {
    if (menuData?.length > 0) {
      setData(menuData.slice());
    }
  };
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isBrowser = typeof window !== "undefined";
  const iOS = isBrowser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const deliveryChargesTemp = localStorage.getItem("deliveryCharges");
  let deliveryChargesLocal;
  if (deliveryChargesTemp !== "undefined") {
    deliveryChargesLocal = JSON.parse(deliveryChargesTemp);
  }
  let time_modal = JSON.parse(localStorage.getItem("timeModal")) || false;

  //  Grouping areas on behalf of citiess
  const areas = useSelector((state) => state.addressReducer.areas);
  // console.log("Areas: ", areas);

  const handleQuery = (event) => {
    console.log(event.target.value);
    setQuery(event.target.value);
  };
  const businessConfig = useSelector(
    (state) => state.businessReducer.businessConfig
  );

  let groupedAreas = areas.reduce(
    (h, obj) =>
      Object.assign(h, { [obj.city]: (h[obj.city] || []).concat(obj) }),
    {}
  );

  //  ************************************************

  useEffect(() => {
    localStorage.setItem("area", JSON.stringify(areas));
  }, [areas]);

  const handleUserDetailChange = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          value,
          error: true,
        },
      });
    } else if (
      (name === "contact" && !isNaN(value)) ||
      (name === "contact" && value.split("")[0] === "+")
    ) {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          value,
          error: false,
        },
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          value,
          error: false,
        },
      });
    }
  };

  function preventNonNumericalInput(e) {
    e = e || window.event;
    let charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    let charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const localStorageLocation = JSON.parse(localStorage.getItem("location"));
  useEffect(() => {
    if (localStorageLocation) {
      setFormValues({
        ...formValues,
        selectedCity: {
          ...formValues["selectedCity"],
          value: localStorageLocation.city,
        },
        selectedArea: {
          ...formValues["selectedArea"],
          value: localStorageLocation.area,
        },
      });
    }
  }, []);

  useEffect(() => {
    fetchAreaInfo();
    getMenu();
    fetchConfig();
    if (time_modal < new Date().getTime()) {
      setLocationModal(true);
    }
  }, []);

  useEffect(() => {
    document.title = businessConfig?.name
      ? `${businessConfig?.name}`
      : `Synevry`;

    let cartFromStorage = JSON.parse(sessionStorage.getItem("cart"));
    const selected_category = menu[0];
    const tax_obj = {
      title: selected_category?.category_tax_name,
      percentage: selected_category?.category_tax_rate,
    };
    const taxesArray = Array.from([tax_obj]);
    if (cartFromStorage) {
      setCart(cartFromStorage);
      calculateCartTotal(cartFromStorage, taxesArray);
    }
  }, [menu, businessConfig]);

  useEffect(() => {
    checkTime();
  }, [businessConfig]);

  useEffect(() => {
    if (deliveryChargesLocal !== null) {
      setDeliveryCharges(deliveryChargesLocal);
    }
  }, [deliveryChargesLocal]);

  function sortStartTime(a, b) {
    if (a.start_time < b.start_time) {
      return -1;
    }
    if (a.start_time > b.start_time) {
      return 1;
    }
    return 0;
  }

  const checkTime = () => {
    setShowTimingModal(false);
    setIsResturantClosed(false);
    if (businessConfig) {
      let timings = businessConfig?.timings || [];
      let day = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
      };

      timings = timings.map((time) => ({ ...time, day: day[time?.day] }));

      let today_day = new Date().getDay();
      let current_time = new Date().getTime();
      // console.log("Today Day: ", today_day);
      // console.log("Timings: ", timings);
      // let day_exist = timings.find((time) => time.day === today_day);
      let day_exist = [];
      timings.forEach((days) => {
        if (days.day === today_day) {
          day_exist.push(days);
        }
      });
      if (day_exist.length === 0 && timings) {
        if (time_modal < new Date().getTime()) {
          setShowTimingModal(true);
        }
        setIsResturantClosed(true);
      } else {
        let is_closed = day_exist[0]?.is_closed === 1 ? true : false;
        let openingTimeMatch = false;

        // ----------------- For Each Start -------------------

        day_exist.forEach((value) => {
          let day_exist_start = value?.start_time;

          day_exist_start = new Date().setHours(
            day_exist_start.split(":")[0],
            day_exist_start.split(":")[1]
          );

          let day_exist_end = value?.end_time;

          day_exist_end = new Date().setHours(
            day_exist_end.split(":")[0],
            day_exist_end.split(":")[1]
          );

          if (is_closed) {
            if (time_modal < new Date().getTime()) {
              setShowTimingModal(true);
            }
            setIsResturantClosed(true);
            return;
          } else {
            if (
              current_time > day_exist_start &&
              current_time < day_exist_end
            ) {
              openingTimeMatch = true;
            } else {
              return;
            }
          }
        });
        // ---------------------- For Each Closed --------------------------
        if (!openingTimeMatch) {
          if (time_modal < new Date().getTime()) {
            setShowTimingModal(true);
          }
          setIsResturantClosed(true);
        }
      }
    }
  };

  useEffect(() => {
    if (businessConfig?.closed_till) {
      const closedTill = businessConfig?.closed_till;
      const isoDate = new Date(closedTill);
      const modifiedDate = new Date(isoDate.setHours(isoDate.getHours() - 10));

      if (new Date().getTime() < new Date(modifiedDate).getTime()) {
        setShowTimingModal(true);
        setIsResturantClosed(true);
      }
    }
  }, [businessConfig]);

  //Code to add css variables
  useEffect(() => {
    if (businessConfig) {
      document.documentElement.style.setProperty(
        "--secondary-color",
        businessConfig?.secondary_color
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        businessConfig?.primary_color
      );
    }
  }, [businessConfig]);

  useEffect(() => {
    let selected_category;
    let tax_obj;
    if (businessConfig?.is_taxable) {
      if (selectedItem) {
        selected_category = menu.filter(
          (m) => m.category_id === selectedItem.categoryKey
        )[0];

        tax_obj = {
          title: selected_category?.category_tax_name,
          percentage: selected_category?.category_tax_rate,
        };
        setTaxes(Array.from([tax_obj]));
      } else {
        selected_category = menu[0];
        tax_obj = {
          title: selected_category?.category_tax_name,
          percentage: selected_category?.category_tax_rate,
        };
        setTaxes(Array.from([tax_obj]));
      }
    } else {
      tax_obj = {
        title: "",
        percentage: 0,
      };
      setTaxes(Array.from([tax_obj]));
    }
  }, [selectedItem, menu, businessConfig]);

  async function fetchAreaInfo() {
    await dispatch(fetchAreas(businessIdentifier));
  }
  async function fetchConfig() {
    await dispatch(fetchBusinessConfig(businessIdentifier));
  }

  async function getMenu() {
    try {
      await dispatch(fetchMenu(businessIdentifier));
      setLoader(false);
      // navigate('/dashboard')
    } catch (err) {
      setLoader(false);
      alert(t("Menu:alertError"));
    }
  }

  const handleChange = (event, newValue) => {
    // console.log("Handle Change: ");
    setValue(newValue);
    // setTabValue(newValue);
    scroller.scrollTo("category" + newValue, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -60, // Scrolls to element + 50 pixels down the page
    });
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const onSelectValue = (val) => {
    if (val.q_type === "checkbox") {
      // console.log("On Select Check Box: ", val);
      const copyData = Array.from(checkBox);
      const index = copyData.findIndex((a) => a.option_id === val.option_id);
      if (index === -1) {
        copyData.push(val);
      } else {
        copyData.splice(index, 1);
      }
      setCheckbox(copyData);
    }
  };

  const onSelectRadio = (val) => {
    if (val.q_type === "select") {
      let copyData = Array.from(radio);
      copyData = copyData.filter((data) => data.q_id !== val.q_id);
      copyData.push(val);
      setRadio(copyData);
    }
  };

  const handleSubmitForm = (e) => {
    if (!loading) {
      e.preventDefault();

      let proceed = true;

      const formFields = Object.keys(formValues);
      let newFormValues = { ...formValues };

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = formValues[currentField].value;

        if (currentValue === "") {
          proceed = false;
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: true,
            },
          };
        } else {
          if (currentField === "email") {
            const isValid = emailValidate();
            if (isValid) {
              newFormValues = {
                ...newFormValues,
                [currentField]: {
                  ...newFormValues[currentField],
                  error: false,
                },
              };
            } else {
              proceed = false;
              newFormValues = {
                ...newFormValues,
                [currentField]: {
                  ...newFormValues[currentField],
                  error: true,
                  errorMessage: "Invalid Email Format",
                },
              };
            }
          } else if (currentField === "contact") {
            const isValid = phoneNumberValidate();
            if (isValid) {
              newFormValues = {
                ...newFormValues,
                [currentField]: {
                  ...newFormValues[currentField],
                  error: false,
                },
              };
            } else {
              proceed = false;
              newFormValues = {
                ...newFormValues,
                [currentField]: {
                  ...newFormValues[currentField],
                  error: true,
                  errorMessage: "Contact Should be in +923121234567 format",
                },
              };
            }
          }
        }
      }

      if (proceed) {
        if (actualPrice > businessConfig?.min_order_amount) {
          createOrderHandler();
        } else {
          setMinimumOrderAlert(true);
        }
      }
      setFormValues(newFormValues);
    }
  };

  useEffect(() => {
    let ids = [];
    menu?.map((value, index) => {
      ids = [...ids, "category" + index];
    });
    setDivIdArray([...ids]);
  }, [menu]);

  async function createOrderHandler() {
    setLoading(true);
    const obj = {
      customer_data: {
        email: formValues.email.value,
        contact_no: formValues.contact.value.toString(),
        name: `${formValues.firstName.value} ${formValues.lastName.value}`,
      },
      order_master: {
        type_id: 1,
        area: formValues.selectedArea.value,
        complete_address: formValues.address.value,
        business_username: businessConfig.username,
        order_items: cart.map((item) => ({
          menu_item_id: item.item_id,
          instruction: item.instruction
            ? item.instruction
            : "No specific instruction",
          item_quantity: item.item_quantity,
          ...(!!item?.options.length && {
            question_options_ids: item.options.map((op) => op.option_id),
          }),
        })),
      },
    };
    const location = {
      city: formValues.selectedCity.value,
      area: formValues.selectedArea.value,
    };
    localStorage.setItem("location", JSON.stringify(location));
    try {
      let res = await dispatch(createOrder(obj));
      if (res.data.payload.order_id) {
        resetState();
        navigate(`/receipt/${res.data.payload.order_id}`, { state: true });
        setLoading(false);
        setCartToLocalStorage([]);
      } else {
        alert("Something went wrong while creating order!");
        resetState();
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  }

  function resetState() {
    setItemModalOpen(false);
    setTimeout(() => {
      setSelectedItem(null);
      setTotalCost(0);
      setCart([]);
    }, 400);
    setFormValues({
      selectedCity: {
        value: "",
        error: false,
        errorMessage: "You select a city",
      },
      selectedArea: {
        value: "",
        error: false,
        errorMessage: "You select a area",
      },
      firstName: {
        value: "",
        error: false,
        errorMessage: "You must enter your first name",
      },
      lastName: {
        value: "",
        error: false,
        errorMessage: "You must enter your last name",
      },
      address: {
        value: "",
        error: false,
        errorMessage: "You must enter your address",
      },
      contact: {
        value: "",
        error: false,
        errorMessage: "You must enter your contact",
      },
      email: {
        value: "",
        error: false,
        errorMessage: "You must enter your email",
      },
    });
  }
  const clearFields = () => {
    console.log("Clear Fields Called!");
    setFormValues((prevState) => ({
      ...prevState,
      firstName: {
        value: "",
        error: false,
        errorMessage: "You must enter your first name",
      },
      lastName: {
        value: "",
        error: false,
        errorMessage: "You must enter your last name",
      },
      address: {
        value: "",
        error: false,
        errorMessage: "You must enter your address",
      },
      contact: {
        value: "+92",
        error: false,
        errorMessage: "You must enter your contact",
      },
      email: {
        value: "",
        error: false,
        errorMessage: "You must enter your email",
      },
    }));
    setInstruction("");
  };
  function emailValidate() {
    let res = String(formValues.email.value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (res) {
      return true;
    } else {
      // alert('Invalid Email Format');
      return false;
    }
  }
  function phoneNumberValidate() {
    let res = String(formValues.contact.value)
      .toLowerCase()
      .match(/^((\+92))(3)([0-9]{9})$/);
    if (res) {
      return true;
    } else {
      // alert("Please Enter Your Number in +92---------- format")
      return false;
    }
  }
  const calculateTaxTotal = (itemCart) => {
    let totalTax = 0;
    let tempNames = [];
    let taxDetail = [];
    let count = {};
    let totalPrice = 0;

    itemCart.map((item) => {
      totalPrice += (item.item_price * item.item_quantity * item.taxRate) / 100;
      item.options.map((option) => {
        totalPrice +=
          (option.option_price * item.item_quantity * item.taxRate) / 100;
      });
      tempNames.push({ name: item.taxName, rate: item.taxRate });
    });
    for (let i = 0; i < tempNames.length; i++) {
      let obj = JSON.stringify(tempNames[i]);
      if (!count[obj]) {
        count[obj] = 1;
        taxDetail.push(tempNames[i]);
      } else {
        count[obj]++;
      }
    }
    setTotalTax(Math.ceil(totalPrice));
    setTaxDetail(taxDetail);
  };
  //Calculate Cart Total
  const calculateCartTotal = (itemCart, taxToCalculate) => {
    // console.log("Item Cart: ", taxes);
    let item_Total = 0;
    let subtotalAmount = 0;
    let actualTotal = 0;
    let discountedTotal = 0;
    let optPrice = 0;
    for (const item of itemCart) {
      item_Total += item.item_quantity;
      // actualTotal += item.item_quantity;
      //   // -------- For Discounted ---------
      actualTotal += parseInt(item.item_quantity) * parseFloat(item.item_price);
      item.options.forEach((op) => {
        actualTotal +=
          parseInt(item.item_quantity) * parseFloat(op.option_price);
        optPrice += parseInt(item.item_quantity) * parseFloat(op.option_price);
      });

      discountedTotal +=
        parseInt(item.item_quantity) * parseFloat(item.discounted_price);
      item.options.forEach((op) => {
        discountedTotal +=
          parseInt(item.item_quantity) * parseFloat(op.option_price);
      });

      subtotalAmount +=
        parseInt(item.item_quantity) * parseFloat(item.discounted_price);
      item.options.forEach((op) => {
        subtotalAmount +=
          parseInt(item.item_quantity) * parseFloat(op.option_price);
      });
    }
    calculateTaxTotal(itemCart);
    setTotalCost(subtotalAmount);
    setActualPrice(actualTotal);
    setDiscountedPrice(discountedTotal);
    let taxesSum = 0.0;
    // // //Taxes calculcation
    // // console.log("Taxes --------", taxes);
    let calculatedTaxes = taxes.length === 0 ? taxToCalculate : taxes;
    // // console.log("Tax 618 ----", calculatedTaxes);
    for (let i = 0; i < calculatedTaxes.length; i++) {
      calculatedTaxes[i].taxAmount = parseFloat(
        actualTotal * (parseFloat(calculatedTaxes[i].percentage) / 100)
      ).toFixed(2);
      taxesSum =
        parseFloat(taxesSum) +
        parseFloat(
          actualTotal * (parseFloat(calculatedTaxes[i].percentage) / 100)
        );
    }
    setTaxes(JSON.parse(JSON.stringify(calculatedTaxes)));
    setTotalQuantity(item_Total);
    setOrderTotal_tax(parseFloat(subtotalAmount));

    setTimeout(() => {
      setRadio([]);
      setCheckbox([]);
    }, 10);

    // setCart(JSON.parse(JSON.stringify(itemCart)));
  };
  const setCartToLocalStorage = (cartForLocalStorage) => {
    sessionStorage.setItem("cart", JSON.stringify(cartForLocalStorage));
  };
  const setActiveOption = (val) => {
    // console.log("',',',',',',','',',', ", val);
  };
  return (
    <div>
      {locationModal && (
        <LocationModal
          open={locationModal}
          handleClose={() => {
            setLocationModal(false);
            localStorage.setItem(
              "timeModal",
              JSON.stringify(new Date().getTime() + 600000)
            );
          }}
          setFormValues={setFormValues}
          formValues={formValues}
          setDeliveryCharges={setDeliveryCharges}
        />
      )}
      {!locationModal && showTimingModal && (
        <TimingModal
          open={showTimingModal}
          handleClose={() => {
            setShowTimingModal(false);
            localStorage.setItem(
              "timeModal",
              JSON.stringify(new Date().getTime() + 600000)
            );
          }}
        />
      )}
      {/* ------------ Header Navbar ------------ */}
      {/* {console.log(businessConfig?.secondary_color)} */}
      <Navbar />
      <HeaderCarousel />
      <AppBar
        position="sticky"
        elevation={5}
        // style={{top: 52}}
        // color={businessConfig?.primary_color}
      >
        <Scrollspy
          className={custom.scrollSpyStyle}
          items={divIdArray}
          onUpdate={(el) => {
            let value = el?.id?.split("y");
            if (value !== undefined) {
              const activeTab = Number(value[1]);
              setTabValue(activeTab);
            }
          }}
          offset={-100}
        >
          <Tabs
            // value={value}

            value={tabValue}
            onChange={handleChange}
            // indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                background: businessConfig?.secondary_color,
                color: "#eebb74",
              },
            }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {menu.map((category, index) => (
              <Tab
                className={custom.customTabs}
                style={{
                  backgroundColor:
                    tabValue === index ? "rgb(252, 180, 22)" : "transparent",
                  borderRadius: tabValue === index ? "10px" : "0",
                  margin: "10px",
                  color: tabValue === index ? "#fff" : "rgb(252, 180, 22)", // Text color for active tab
                }}
                label={category.category || category.item_category}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Scrollspy>
      </AppBar>
      <CustomSearch
        debouncedTerm={debouncedTerm}
        setDebouncedTerm={setDebouncedTerm}
      />
      <div>
        <Grid container>
          {/* // Main Products Page */}
          <Grid item xs={12} md={8} sm={12} style={{ padding: 25 }}>
            {data.length > 0 ? (
              data.map((category, index) => {
                return (
                  <Element
                    name={t("Menu:category") + index}
                    style={{ marginBottom: 30 }}
                    id={"category" + index}
                  >
                    <TrackVisibility>
                      {({ isVisible }) => {
                        if (isVisible) setValue(index);
                        return (
                          <Grid container spacing={4}>
                            {/* Top Title */}
                            <Grid item xs={12} md={12} sm={12}>
                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  margin: "0 auto",
                                }}
                              >
                                <h2
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    color: "white",
                                    textShadow:
                                      "8px 8px 16px rgba(0, 0, 0, 0.7)",
                                    margin: 0,
                                    padding: 0,
                                    zIndex: 1,
                                    fontSize: fontSize,
                                    fontFamily: "'Poppins', ui-sans-serif",
                                    textAlign: "center",
                                  }}
                                >
                                  {category.category || category.item_category}
                                </h2>
                                <img
                                  src={
                                    "https://static.vecteezy.com/system/resources/thumbnails/038/817/011/small/ai-generated-minimalist-capturing-golden-crispy-fries-arranged-neatly-on-a-clean-surface-free-photo.jpeg"
                                  }
                                  alt={
                                    category.category || category.item_category
                                  }
                                  style={{
                                    width: "100%",
                                    height: "130px",
                                    borderRadius: "20px",
                                    // filter: "blur(1.5px)",
                                    /* From https://css.glass */
                                    background: "rgba(255, 255, 255, 0.2)",
                                    borderRadius: "16px",
                                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                                    backdropFilter: "blur(5px)",
                                    WebkitBackdropFilter: "blur(5px)",
                                    border:
                                      "1px solid rgba(255, 255, 255, 0.3)",
                                  }}
                                />
                                {/* <p style={{ marginBottom: 0 }}>
                                  {category.description}
                                </p> */}
                              </div>
                            </Grid>

                            {category?.menu_items?.map((item, i) => {
                              return (
                                <Grid item xs={12} md={4} sm={6}>
                                  <MenuItem
                                    setInstruction={setInstruction}
                                    isDisabled={isResturantClosed}
                                    details={item}
                                    onAddToCart={() => {
                                      // handleOpen();
                                      if (
                                        item?.questions &&
                                        item?.questions?.length === 0
                                      ) {
                                        let itemMatched = false;
                                        let currentCart = cart;
                                        let currentItem = {
                                          ...item,
                                          categoryKey: category.category_id,
                                          itemKey: item.item_id,
                                          options: [...radio, ...checkBox],
                                          item_quantity: itemQuantity,
                                          itemUniqueId: item.item_id.toString(),
                                          taxName: category.category_tax_name,
                                          taxRate: category.category_tax_rate,
                                          taxAmount: Math.ceil(
                                            (item.item_price / 100) *
                                              category.category_tax_rate
                                          ),
                                        };
                                        const newCart = cart.map((value) => {
                                          if (
                                            value.itemUniqueId ===
                                            currentItem.itemUniqueId
                                          ) {
                                            itemMatched = true;
                                            return {
                                              ...value,
                                              item_quantity:
                                                value.item_quantity + 1,
                                            };
                                          } else {
                                            return { ...value };
                                          }
                                        });
                                        if (
                                          !itemMatched &&
                                          !isResturantClosed
                                        ) {
                                          newCart.push(currentItem);
                                        }
                                        setSelectedItem({
                                          ...item,
                                          categoryKey: category.category_id,
                                          itemKey: item.item_id,
                                        });
                                        currentCart = newCart;
                                        calculateCartTotal(currentCart);
                                        setCartToLocalStorage(currentCart);
                                        setCart(
                                          JSON.parse(
                                            JSON.stringify(currentCart)
                                          )
                                        );
                                      } else {
                                        setSelectedItem({
                                          ...item,
                                          categoryKey: category.category_id,
                                          itemKey: item.item_id,
                                          taxName: category.category_tax_name,
                                          taxRate: category.category_tax_rate,
                                          taxAmount: Math.ceil(
                                            (item.item_price / 100) *
                                              category.category_tax_rate
                                          ),
                                        });
                                        setItemModalOpen(true);
                                      }
                                    }}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        );
                      }}
                    </TrackVisibility>
                  </Element>
                );
              })
            ) : (
              <div className={custom.EmptyComponent}>
                <h3>No Data Found</h3>
              </div>
            )}
            {data.length > 0 ? <hr style={{ opacity: 0.5 }} /> : null}
            <div
              style={{
                marginTop: 40,
              }}
            >
              <h2
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowTable(!showTable);
                }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {businessConfig?.name} Shop Timings{" "}
                </span>
                <span
                  style={{
                    display: "flex", // Make the icon a flex item
                    alignItems: "center", // Ensure vertical alignment with the text
                    marginTop: "5px", // Add space between text and icon
                  }}
                >
                  {showTable ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </span>
              </h2>
              {/* <h2
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowTable(!showTable);
                }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {businessConfig?.name} Shop Timings
                </span>
                <span
                  style={{
                    display: "flex", // Make the icon a flex item
                    alignItems: "center", // Ensure vertical alignment with the text
                    marginTop: "4px", // Add space between text and icon
                  }}
                >
                  {showTable ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </span>
              </h2> */}

              {showTable ? (
                <TimingTable timings={businessConfig?.timings} />
              ) : null}
            </div>
          </Grid>
          {/* // Your Cart */}
          <Grid item xs={0} md={4} sm={0} className={custom.gridCart}>
            <Grid container className={classes.cartContainer}>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                style={{
                  height: size.height,
                }}
                className={custom.cartColumn}
              >
                <div
                  style={{ overflow: "hidden", height: "100%", width: "100%" }}
                >
                  <h4 style={{ marginRight: 10, fontSize: 35 }}>
                    {t("Menu:yourCart")}
                  </h4>
                  {cart.length === 0 ? (
                    <>
                      <img
                        src={emptyCart}
                        alt=""
                        className={custom.emptyCarts}
                      />
                      <p>{t("Menu:emptyCart")}</p>
                    </>
                  ) : (
                    <div
                      style={{
                        maxHeight: size.height * 0.99,
                        height: size.height * 0.78,
                      }}
                      className={custom.cartCard}
                    >
                      {cart.map((item, index) => {
                        console.log("cart", cart);
                        return (
                          <CartItem
                            details={item}
                            // discount={actualPrice-discountedPrice}
                            item={item}
                            onDelete={() => {
                              let currentCart = cart;

                              currentCart.splice(index, 1);
                              setCartToLocalStorage(currentCart);
                              calculateCartTotal(currentCart);
                              setCart(JSON.parse(JSON.stringify(currentCart)));
                            }}
                            AddQuantity={() => {
                              let currentCart = cart;
                              currentCart[index].item_quantity =
                                currentCart[index].item_quantity + 1;
                              setCartToLocalStorage(currentCart);
                              calculateCartTotal(currentCart);
                              setCart(JSON.parse(JSON.stringify(currentCart)));
                            }}
                            MinusQuantity={() => {
                              let currentCart = cart;
                              if (currentCart[index].item_quantity > 1) {
                                currentCart[index].item_quantity =
                                  currentCart[index].item_quantity - 1;
                                calculateCartTotal(currentCart);
                                setCartToLocalStorage(currentCart);
                                setCart(
                                  JSON.parse(JSON.stringify(currentCart))
                                );
                              } else {
                                setCartToLocalStorage(currentCart);
                                calculateCartTotal(currentCart);
                                setCart(
                                  JSON.parse(JSON.stringify(currentCart))
                                );
                              }
                            }}
                          />
                        );
                      })}
                      {cart.length > 0 && (
                        <div className={custom.cartTotal}>
                          <div style={{ marginBottom: 30 }}>
                            <div className={custom.cartAmount}>
                              <p style={{ margin: 0 }}>{t("Menu:itemTotal")}</p>
                              {/* <h4 style={{ margin: 0, marginRight: 10 }}>Rs {totalCost}</h4> */}
                              <h4 style={{ margin: 0, marginRight: 10 }}>
                                {showPrice(actualPrice)}
                              </h4>
                            </div>
                            <div className={custom.cartAmount}>
                              <p style={{ margin: 0 }}>{t("Menu:discount")}</p>
                              <h4 style={{ margin: 0, marginRight: 10 }}>
                                {showPrice(actualPrice - discountedPrice)}
                              </h4>
                            </div>
                            {businessConfig?.is_taxable ? (
                              <div className={custom.cartAmount}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <p
                                    style={{ margin: "0px 0px 5px 0px" }}
                                  >{`${t("Menu:tax")}`}</p>
                                  {taxDetail.length > 0 &&
                                    taxDetail.map((ele) => (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        {`${ele.name} ( ${ele.rate}%)`}
                                      </span>
                                    ))}
                                </div>
                                <h4 style={{ margin: 0, marginRight: 10 }}>
                                  {showPrice(totalTax)}
                                </h4>
                              </div>
                            ) : null}
                            <div className={custom.cartAmount}>
                              <p style={{ margin: 0 }}>
                                {t("Menu:deliveryCharges")}
                              </p>
                              <h4 style={{ margin: 0, marginRight: 10 }}>
                                {showPrice(deliveryCharges)}
                              </h4>
                            </div>
                            <div className={custom.cartAmount}>
                              <p style={{ margin: 0 }}>
                                {t("Menu:subTotal")}{" "}
                                {businessConfig?.is_taxable ? (
                                  <span style={{ fontSize: 10 }}>
                                    (incl. Tax)
                                  </span>
                                ) : null}
                              </p>
                              <h4 style={{ margin: 0, marginRight: 10 }}>
                                {showPrice(
                                  Math.ceil(orderTotal_tax) +
                                    deliveryCharges +
                                    totalTax
                                )}
                              </h4>
                            </div>
                          </div>

                          <Button
                            onClick={() => {
                              setPlaceOrderModalOpen(true);
                            }}
                            fullWidth
                            variant="contained"
                            className={custom.placeOrderButton}
                          >
                            {t("Menu:placeOrder")}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  {}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <ItemModal
        ModalClose={() => {
          setItemModalOpen(false);
          setQuantity(1);
          setCheckbox([]);
          setRadio([]);
          // setSelectedItem(null);
        }}
        instruction={instruction}
        setInstruction={setInstruction}
        showModal={itemModalOpen}
        selected_item={selectedItem}
        select_value_handler={onSelectValue}
        select_radio_handler={onSelectRadio}
        checkbox_state={checkBox}
        radio_state={radio}
        item_quantity_state={itemQuantity}
        item_quantity_handler={setQuantity}
        cartCalculator={calculateCartTotal}
        cart_handler={setCart}
        cart_state={cart}
        isDisabled={isResturantClosed}
        setCartToLocalStorage={setCartToLocalStorage}
      />
      {cart.length > 0 && (
        <>
          <div className={classes.viewCartMobile} style={{ padding: 20 }} />
          <div
            className={`${classes.viewCartMobile} ${custom.itemModal}`}
            onClick={() => {
              setPlaceOrderModalOpen(true);
              setShowCartMobile(true);
            }}
          >
            <div className={custom.itemModalBody}>
              <div className={custom.itemModalBodyContent}>
                <ShoppingBasketOutlined />{" "}
                <div className={custom.itemModalQty}>{totalQuantity}</div>
              </div>
              <div>
                <b className={custom.itemModalViewCart}>{t("Menu:viewCart")}</b>
              </div>
              <div>
                {" "}
                {showPrice(
                  Math.ceil(orderTotal_tax) + deliveryCharges + totalTax
                )}
              </div>
            </div>
          </div>

          <SwipeableDrawer
            anchor={"right"}
            open={placeOrderModalOpen}
            onClose={() => {
              setPlaceOrderModalOpen(false);
            }}
            onOpen={() => {
              setPlaceOrderModalOpen(true);
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            disableSwipeToOpen={false}
          >
            <div className={custom.drawermain}>
              {showDrawer ? null : (
                <>
                  <div style={{ height: size.height }}>
                    <div className={custom.drawerSummary}>
                      <h2 className={custom.drawerSummaryHead}>
                        {t("Menu:orderSummary")}
                      </h2>

                      <div className={custom.drawerSummaryCart}>
                        {cart.map((item, index) => {
                          return (
                            <CartItem
                              details={item}
                              item={item}
                              // discount={actualPrice-discountedPrice}
                              hideDelete
                              disableInputs
                              onDelete={() => {
                                let currentCart = cart;
                                currentCart.splice(index, 1);
                                setCartToLocalStorage(currentCart);
                                calculateCartTotal(currentCart);
                                setCart(
                                  JSON.parse(JSON.stringify(currentCart))
                                );
                              }}
                              AddQuantity={() => {
                                let currentCart = cart;
                                currentCart[index].item_quantity =
                                  currentCart[index].item_quantity + 1;
                                setCartToLocalStorage(currentCart);
                                calculateCartTotal(currentCart);
                                setCart(
                                  JSON.parse(JSON.stringify(currentCart))
                                );
                              }}
                              MinusQuantity={() => {
                                let currentCart = cart;
                                if (currentCart[index].item_quantity > 1) {
                                  currentCart[index].item_quantity =
                                    currentCart[index].item_quantity - 1;
                                  calculateCartTotal(currentCart);
                                  setCartToLocalStorage(currentCart);
                                  setCart(
                                    JSON.parse(JSON.stringify(currentCart))
                                  );
                                } else {
                                  calculateCartTotal(currentCart);
                                  setCartToLocalStorage(currentCart);
                                  setCart(
                                    JSON.parse(JSON.stringify(currentCart))
                                  );
                                }
                              }}
                            />
                          );
                        })}
                      </div>
                      {cart.length > 0 && (
                        <div
                          className={custom.drawerSummaryTotal}
                          style={
                            showDrawer
                              ? { display: "none" }
                              : { borderSpacing: 0 }
                          }
                        >
                          <div>
                            <div className={custom.mobileTotlaItemAmount}>
                              <p style={{ margin: 0 }}>{t("Menu:itemTotal")}</p>
                              {/* <h4 style={{ margin: 0, marginRight: 10 }}>Rs {totalCost}</h4> */}
                              <h4 style={{ margin: 0 }}>
                                {showPrice(actualPrice)}
                              </h4>
                            </div>
                            <div className={custom.drawerSummarySubTotal}>
                              <p style={{ margin: 0 }}>{t("Menu:discount")}</p>
                              <h4 style={{ margin: 0 }}>
                                {showPrice(actualPrice - discountedPrice)}
                              </h4>
                            </div>
                            <div className={custom.mobileTaxDetails}>
                              {businessConfig?.is_taxable ? (
                                <div className={custom.mobileCartAmount}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <p
                                      style={{ margin: "0px 0px 5px 0px" }}
                                    >{`${t("Menu:tax")}`}</p>
                                    {taxDetail.length > 0 &&
                                      taxDetail.map((ele) => (
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {`${ele.name} ( ${ele.rate}%)`}
                                        </span>
                                      ))}
                                  </div>
                                  <h4 style={{ margin: 0, marginRight: 10 }}>
                                    {showPrice(totalTax)}
                                  </h4>
                                </div>
                              ) : null}
                              <div className={custom.mobileCartAmount}>
                                <p style={{ margin: 0 }}>
                                  {t("Menu:deliveryCharges")}
                                </p>
                                <h4 style={{ margin: 0, marginRight: 10 }}>
                                  {showPrice(deliveryCharges)}
                                </h4>
                              </div>
                            </div>
                            <div className={custom.drawerSummarySubTotal}>
                              <p style={{ margin: 0 }}>
                                {t("Menu:subTotal")}{" "}
                                {businessConfig?.is_taxable ? (
                                  <span style={{ fontSize: 10 }}>
                                    (incl. Tax)
                                  </span>
                                ) : null}
                              </p>
                              <h4 style={{ margin: 0 }}>
                                {showPrice(
                                  Math.ceil(orderTotal_tax) +
                                    deliveryCharges +
                                    totalTax
                                )}{" "}
                              </h4>
                            </div>
                          </div>
                          <div className={custom.checkOutButton}>
                            <Button
                              fullWidth
                              variant="outlined"
                              className={custom.customOrderButton}
                              onClick={() => {
                                setPlaceOrderModalOpen(false);
                                setShowDrawer(false);
                              }}
                              style={{ marginBottom: 5 }}
                            >
                              Go to Shop
                            </Button>
                            <Button
                              fullWidth
                              variant="contained"
                              className={custom.placeOrderButton}
                              onClick={() => setShowDrawer(true)}
                            >
                              Check Out
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div
                style={
                  showDrawer
                    ? {
                        display: "block",
                        height: size.height,
                        width: size.width / 3.5,
                      }
                    : {
                        // display:'none',
                        height: size.height,
                        width: size.width / 3.5,
                      }
                }
                className={custom.drawerDetails}
              >
                <form noValidate onSubmit={handleSubmitForm}>
                  <div className={custom.drawerDetailsHead}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <h2
                        className={custom.drawerDetailsHeading}
                        onClick={() => setShowDrawer(false)}
                      >
                        <ChevronLeftIcon
                          style={{
                            height: "100%",
                            verticalAlign: "text-bottom",
                            fontSize: "30px",
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                          className={custom.backIconCheckOut}
                        />
                        {t("Menu:details")}
                      </h2>
                    </div>

                    <div className={custom.drawerDetailsPayment}>
                      <h4>
                        {t("Menu:deliveryDetails")}{" "}
                        <span className={custom.requiredText}>
                          {t("Menu:allFieldsMandatory")}(*)
                        </span>
                      </h4>

                      <div style={{ width: "100%" }}>
                        <TextField
                          variant="outlined"
                          style={{ width: "100%" }}
                          label={t("Menu:firstName")}
                          submit
                          name="firstName"
                          onChange={handleUserDetailChange}
                          error={formValues.firstName.error}
                          value={formValues.firstName.value}
                          helperText={
                            formValues.firstName.error &&
                            formValues.firstName.errorMessage
                          }
                        />
                      </div>

                      <div style={{ width: "100%", marginTop: 15 }}>
                        <TextField
                          label={t("Menu:lastName")}
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="lastName"
                          onChange={handleUserDetailChange}
                          error={formValues.lastName.error}
                          value={formValues.lastName.value}
                          helperText={
                            formValues.lastName.error &&
                            formValues.lastName.errorMessage
                          }
                        />
                      </div>
                      <FormControl
                        fullWidth
                        style={{ margin: "10px 0" }}
                        error={formValues.selectedCity.error}
                      >
                        <InputLabel id="select_city">
                          {t("Menu:city")}
                        </InputLabel>
                        <Select
                          label="City"
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="selectedCity"
                          onChange={handleUserDetailChange}
                          value={formValues.selectedCity.value}
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "30vh" } },
                          }}
                        >
                          {Object.keys(groupedAreas).length &&
                            Object.keys(groupedAreas).map((city) => {
                              return (
                                <MUIMenuItem value={city}>{city}</MUIMenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>

                      <FormControl
                        fullWidth
                        style={{ margin: "10px 0" }}
                        error={formValues.selectedArea.error}
                      >
                        <InputLabel id="select_area">
                          {" "}
                          {t("Menu:area")}
                        </InputLabel>
                        <Select
                          label="Area"
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="selectedArea"
                          onChange={(e) => {
                            handleUserDetailChange(e);
                            areas.forEach((element) => {
                              if (element?.area === e.target.value) {
                                setDeliveryCharges(element?.delivery_charges);
                                localStorage.setItem(
                                  "deliveryCharges",
                                  JSON.stringify(element?.delivery_charges)
                                );
                                localStorage.setItem(
                                  "location",
                                  JSON.stringify({
                                    city: element?.city,
                                    area: element?.area,
                                  })
                                );
                              }
                            });
                          }}
                          value={formValues.selectedArea.value}
                          disabled={formValues.selectedCity.value === ""}
                          // disabled={deliveryCharges!==null? true : false}
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "30vh" } },
                          }}
                        >
                          {Object.values(groupedAreas).length &&
                            groupedAreas[formValues.selectedCity.value]?.map(
                              (area) => (
                                <MUIMenuItem value={area.area}>
                                  {area.area}
                                </MUIMenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>

                      <div style={{ width: "100%", marginTop: 8 }}>
                        <TextField
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="address"
                          label={t("Menu:address")}
                          onChange={handleUserDetailChange}
                          error={formValues.address.error}
                          value={formValues.address.value}
                          helperText={
                            formValues.address.error &&
                            formValues.address.errorMessage
                          }
                        />
                      </div>

                      <div style={{ width: "100%", marginTop: 15 }}>
                        <TextField
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="contact"
                          // pattern="[1-9]"
                          type="text"
                          onKeyPress={(event) => {
                            // preventNonNumericalInput(event)
                            if (event.which === 43) {
                            } else if (event.which < 48 || event.which > 57) {
                              event.preventDefault();
                            }
                          }}
                          label={t("Menu:contactNumber")}
                          onChange={handleUserDetailChange}
                          error={formValues.contact.error}
                          value={formValues.contact.value}
                          helperText={
                            formValues.contact.error &&
                            formValues.contact.errorMessage
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          marginTop: 15,
                        }}
                      >
                        <TextField
                          variant="outlined"
                          style={{ width: "100%" }}
                          name="email"
                          label={t("Menu:emailAddress")}
                          onChange={handleUserDetailChange}
                          error={formValues.email.error}
                          value={formValues.email.value}
                          helperText={
                            formValues.email.error &&
                            formValues.email.errorMessage
                          }
                        />
                      </div>
                    </div>

                    <Snackbar
                      open={minimumOrderAlert}
                      onClose={() => setMinimumOrderAlert(false)}
                      autoHideDuration={4000}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      ContentProps={{
                        sx: {
                          background: businessConfig?.secondary_color,
                          fontWeight: "500",
                        },
                      }}
                      TransitionComponent={(props) => (
                        <Slide {...props} direction="up" />
                      )}
                      message={`Minimum Order Amount is ${businessConfig?.min_order_amount}`}
                    />
                    <div className={custom.drawerDetailsOrderConfirmation}>
                      <div className={custom.drawerDetailsOrderButton}>
                        <Button
                          onClick={() => {
                            clearFields();
                            setPlaceOrderModalOpen(false);
                            setShowDrawer(false);
                          }}
                          fullWidth
                          variant="outlined"
                          disableElevation
                          className={custom.customOrderButton}
                          disabled={loading}
                        >
                          {t("Menu:cancel")}
                        </Button>
                        {isResturantClosed ? null : (
                          <>
                            <div style={{ width: 15 }} />
                            <Button
                              fullWidth
                              variant="contained"
                              className={custom.placeOrderButton}
                              type="submit"
                              disableElevation
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                t("Menu:confirmOrder")
                              )}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* {list(anchor)} */}
          </SwipeableDrawer>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.businessReducer.menu,
    business: state.businessReducer.business,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);
