import axios from 'axios';
import { serverUrl } from '../../config';


export const createOrder = (data) => async (dispatch) => {
    return axios({
        url: "api/user/menu/createOrder",
        method: "POST",
        baseURL: serverUrl,
        data: { data: data }
    }).then(res => {
        console.warn('createOrder', res);
        return res
    }).catch(err => {
        throw new Error(err)
    })

};
