let data = window.localStorage.getItem('currencyData');
let currencyData;
if(data){
 currencyData = JSON.parse(data);
}else{
currencyData = {symbol: 'Rs.', prefix: 1}
}
export const showPrice = (price) => {
  return currencyData.prefix === 1
    ? ` ${currencyData.symbol} ${price} `
    : ` ${price} ${currencyData.symbol} `;
};
