import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise';

import businessReducer from '../reducers/businessReducer';
import addressReducer from '../reducers/addressInfo';

const rootReducer = combineReducers({
  businessReducer,
  addressReducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(promise, thunk)
);

export const persistor = persistStore(store);
