import React from 'react';
import Select from '@mui/material/Select';
import MUIMenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import ChickyBellerose from '../assets/ChickyBellerose.jpg';
import ChickyBellerose from '../assets/toast-tab.svg';

import UberEats from '../assets/uber-eats.jpg';
import DoorDash from '../assets/doordash-logo-web.png';

import './Navbar.css';

export default function Navbar() {
  const businessConfig = useSelector(
    (state) => state.businessReducer.businessConfig
  );
  console.log('bussinessConfig', businessConfig);
  const { i18n } = useTranslation();
  const selected = (val) => {
    i18n.changeLanguage(val);
  };

  const navbar = document.querySelector('.nav-fixed');
  const select = document.querySelector('.language-select');
  window.onscroll = () => {
    if (window.scrollY > 300) {
      navbar.classList.add('nav-active');
      select.classList.add('language-select-active');
      // console.log(window.scrollY)
    } else {
      navbar.classList.remove('nav-active');
      select.classList.remove('language-select-active');
      // console.log("else ", window.scrollY)
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 111,
        width: '100%',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          // position: 'sticky',
          zIndex: 123,
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 5,
          paddingBottom: 5,
          width: '98%',
          // alignSelf: 'center',
          // alignItems: 'center',
          flexWrap: 'wrap',
          // backgroundColor: 'red',
          // height: 45,
          // backgroundColor: '#ffffff',
          // background: 'linear-gradient(90deg, rgba(255,255,255,0.48503151260504207) 48%, rgba(162,41,27,0.5494572829131652) 100%)',
          // background: 'linear-gradient(90deg, rgba(255,255,255,0.48503151260504207) 33%, rgba(162,41,27,0.7063200280112045) 100%)'
        }}
        className='nav-fixed'
      >
        <div
          style={{
            // marginLeft: 25,
            marginTop: 2,

            display: 'flex',
            flexDirection: 'row',

            gap: 20,
          }}
        >
          <a style={{ height: 40, width: 50 }}>
            <img
              style={{ height: '100%', width: '100%' }}
              src={`${businessConfig?.images?.logo}?${new Date().getTime()}`}
              alt='logo'
            />
          </a>

          <a
            href='https://www.ubereats.com/store/chickys/NyT2fv7GRYmQqSSSWZbY7A?srsltid=AfmBOoreKt3IZ5TaoqV_QxTg4LAmjXk02ChzS7l5F6EhQuWR-Yag6nrF'
            target='_blank'
            rel='noopener noreferrer'
            style={{ height: 40, width: 50 }}
          >
            <img
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              src={UberEats}
              alt='logo'
            />
          </a>
          <a
            href='https://order.toasttab.com/online/chickys-bellerose-248-43-jericho-tpke'
            target='_blank'
            rel='noopener noreferrer'
            style={{ height: 40, width: 50 }}
          >
            <img
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              src={ChickyBellerose}
              alt='logo'
            />
          </a>
          <a
            href='https://www.doordash.com/store/824136?utm_source=mx_share'
            target='_blank'
            rel='noopener noreferrer'
            style={{ height: 40, width: 50 }}
          >
            <img
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              src={DoorDash}
              alt='logo'
            />
          </a>
        </div>

        <div>
          <div className='header-language' style={{ marginTop: 1 }}>
            {/* <FormControl fullWidth variant='outlined' >
            <InputLabel
              id="language-label"
              sx={{
                color: '#fff',
                fontWeight: 500,
                textAlign: 'center',
              }}
            >
              Language
            </InputLabel>
            <Select
              variant="outlined"
              sx={{
                width: 120,
                height:40,
                paddingTop: 1,
                marginTop: 1,
                color: '#000',
                fontWeight: 500,
                borderColor: "#fff",
                '& .MuiOutlinedInput-root:hover': {
                  '& > fieldset': {
                    borderColor: '#A2291B',
                  },
                },
              }}
              id="language-select"
              label="Language"
              labelId="language-label"
              onChange={(val) => selected(val.target.value)}
            >
              <MUIMenuItem value="en">English</MUIMenuItem>
              <MUIMenuItem value="de">German</MUIMenuItem>
            </Select>
          </FormControl> */}
            <Form.Select
              aria-label='Default select example'
              className='language-select'
              onChange={(val) => selected(val.target.value)}
            >
              <option value='en'>English</option>
              <option value='de'>German</option>
            </Form.Select>
          </div>
        </div>
      </div>
    </div>
  );
}
