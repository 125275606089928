const { io } = require('socket.io-client');

const socket = io('http://localhost:3002', {
  cors: {
    origin: 'http://localhost:3001',
    credentials: true,
  },
  transports: ['websocket'],
});

export default socket;
