import React, { useEffect } from "react";
// import MaterialTable from "material-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function TimingTable({ timings }) {
  // ------------ Columns For Material Table -----------
  const columns = [
    { title: "Days", field: "day", editable: false },
    {
      title: "Opening Time",
      field: "start_time",
      editable: false,
    },
    {
      title: "Closing Time",
      field: "end_time",
      editable: false,
    },
  ];

  const handleUpperCase = (data) => {
    let day = data.split("");
    let first = day[0];
    day[0] = first.toUpperCase();
    return day.join("");
  };

  // useEffect(() => {
  //   const timingArray = timings.map((value) => {

  //   })
  // }, [])

  function tConv24(time24) {
    let ts = time24;
    let H = +ts.substr(0, 2);
    let h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    let ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: "#d6d4d4" }}>
            <TableCell>Day</TableCell>
            <TableCell align="right">Opening Time</TableCell>
            <TableCell align="right">Closing Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timings &&
            timings?.map((row, index) => (
              <TableRow
                //   key={row.name}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: index % 2 != 0 ? "#f5f5f5" : "#ffffff", // Alternating row colors
                }}
              >
                <TableCell component="th" scope="row">
                  {handleUpperCase(row.day)}
                </TableCell>
                <TableCell align="right">{tConv24(row.start_time)}</TableCell>
                <TableCell align="right">{tConv24(row.end_time)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
