import { TextField } from "@mui/material";
import React, { useState } from "react";
import classes from "./CustomSearch.module.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowBack";
const CustomSearch = ({ debouncedTerm, setDebouncedTerm }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={classes.ComponentWrapper}>
      <div className={classes.MainWrapper}>
        <TextField
          variant="standard"
          placeholder={isFocused ? "" : "Search items"}
          fullWidth
          value={debouncedTerm}
          onFocus={() => setIsFocused(true)} // Set focus state
          onBlur={() => setIsFocused(false)} // Reset focus state when out of focus
          onChange={(e) => setDebouncedTerm(e.target.value)}
          InputProps={{
            startAdornment: isFocused ? (
              <ArrowForwardIcon sx={{ paddingRight: "10px" }} />
            ) : (
              <SearchIcon sx={{ paddingRight: "10px" }} />
            ),
            disableUnderline: true,
          }}
        />
      </div>
    </div>
  );
};

export default CustomSearch;
