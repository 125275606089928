import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import custom from "./Component.module.css";
import { useTranslation } from "react-i18next";
import closedImage from "../assets/closed.png";
import { Typography } from "@mui/material";
const style = {
  bgcolor: "background.paper",
  height: "auto",
};

function TimingModal({ open, handleClose }) {
  const { t } = useTranslation();

  const businessConfig = useSelector(
    (state) => state.businessReducer.businessConfig
  );

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className={custom.modalScrollbar}
          // style={{ height: '300px' }}
        >
          <img
            src={closedImage}
            width="100%"
            style={{ margin: "0 auto", display: "block", objectFit: "contain" }}
          />
          <Typography variant="body1" align="center">
            {t("Menu:closedMessage")}
          </Typography>
          <Button
            variant="contained"
            className={custom.checkMenuBtn}
            onClick={() => handleClose()}
          >
            {t("Menu:checkMenu")}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default TimingModal;
